//
// Page
//

.app-root{
    height: 100%;
}

.app-page {
    display: flex;
}